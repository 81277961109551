import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {AboutSection, Avatar, Title, Text} from './style';
import {SectionIntro, ContainerLayout, ResumeButton} from "../common";

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "pep-convo.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SectionIntro>
        <ContainerLayout>
          <AboutSection>
            <div>
              <Title> Hello, I’m James </Title>
              <Text> I'm a Software Engineer by trade, with 20+ years experience across a wide range of roles, industries and companies, I live in the UK on the south coast, within striking distance of London.</Text>
              <Text> Over the years I've used many different technologies and consider myself fairly agnostic and able to pick up whatever stack is most suitable to the current challenge.</Text>
              <Text> Most recently I've been working with <a href="https://github.com/60decibels/pytrics" target="_blank" rel="noreferrer" className="text-primary lined-link">Python</a> and <a href="https://app.60decibels.com/covid-19" target="_blank" rel="noreferrer" className="text-primary lined-link">JavaScript</a> and using various parts of AWS to build SaaS solutions.</Text>
              <Text> I'm as interested in how teams collaborate to solve challenging problems as much as the solutions themselves. </Text>
              <Text> I have experience in both technical and managerial roles, and am happy writing code, building CI/CD processes, setting up and running agile delivery or tackling product ownership.</Text>
              {/* <Text> I am excited by interesting projects and relish the chance to contribute my skills and experience to help innovative companies achieve their goals.</Text> */}
              <ResumeButton href="JamesHeywoodCV2021-IC.pdf" target="_blank"> Download CV </ResumeButton>
            </div>
            <div>
              <Avatar fluid={data.placeholderImage.childImageSharp.fluid} alt="user photo" style={{'margin-top': '4rem'}} />
            </div> 
          </AboutSection>
        </ContainerLayout>
      </SectionIntro>
    </>
  )
}


export default About
